export enum AnimationType {
  fade = "fade",
  fadeUp = "fade-up",
  fadeDown = "fade-down",
  fadeLeft = "fade-left",
  fadeRight = "fade-right",
  fadeUpLeft = "fade-up-left",
  fadeUpRight = "fade-up-right",
  fadeDownLeft = "fade-down-left",
  fadeDownRight = "fade-down-right",
  flipUp = "flip-up",
  flipDown = "flip-down",
  flipLeft = "flip-left",
  flipRight = "flip-right",
  slideUp = "slide-up",
  slideDown = "slide-down",
  slideLeft = "slide-left",
  slideRight = "slide-right",
  zoomIn = "zoom-in",
  zoomInUp = "zoom-in-up",
  zoomInDown = "zoom-in-down",
  zoomInLeft = "zoom-in-left",
  zoomInRight = "zoom-in-right",
  zoomOut = "zoom-out",
  zoomOutUp = "zoom-out-up",
  zoomOutDown = "zoom-out-down",
  zoomOutLeft = "zoom-out-left",
  zoomOutRight = "zoom-out-right",
}
